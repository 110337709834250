const versionNumber = 0;
const versionDate = '2024.11.14 15:10';

export const environment = {
  apiUrl: 'https://api.panel-inwestora.corvus-it.com',
  envName: "test",
  feVersion: `${versionDate}`,
  beVersion: 'DATE',
  inProgress: true,
  storageHashKey: "YIASKsdsadtlvnit4ow8qrvc4opwehrcloifewwoOWH&*D27qo9y7",
  isBeta: false,
};
