import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './_modules/auth/auth.component';

const routes: Routes = [
  {
    path: 'investor-form',
    loadChildren: () =>
      import('./_modules/investor-form/investor-form.module').then(
        (m) => m.InvestorFormModule
      ),
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'auth', pathMatch: 'full' },
      {
        path: 'auth',
        loadChildren: () =>
          import('./_modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./_modules/application/application.module').then(
            (m) => m.ApplicationModule
          ),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
