import { Injectable } from '@angular/core';
import {
  AUTH_CONSTANTS,
  LoginRequest,
  LoginResponse,
  RemindPasswordRequest,
  RemindPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UserDataResponse,
  UserPerm,
} from './models/auth.model';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiResponseModel } from '../@shared/models/responses/api-response.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const AUTH_KEY = 'auth_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string | null = null;
  private tokenExpirationDate: Date | null = null;

  private user: UserDataResponse | null = null;
  private activeAuthState: AuthState = 'loading';

  private _userData: BehaviorSubject<UserDataResponse | null> =
    new BehaviorSubject(this.user);
  private _authState: BehaviorSubject<AuthState> = new BehaviorSubject(
    this.activeAuthState,
  );

  get userPermissions(): UserPerm[] {
    return this.user?.permissions ?? [];
  }

  get isSuperAdmin(): boolean {
    return this.user?.accountType?.internalName === 'SystemAdministrator';
  }

  get allowEntities() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Entity' && permission.read,
      )
    );
  }

  get allowDocuments() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.read,
      )
    );
  }

  get allowInvoicesReturn() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'InvoicesReturn' &&
          permission.update,
      )
    );
  }

  get allowOffers() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.read,
      )
    );
  }

  get allowRecepcionst() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) => permission.role.internalName === 'Recepcja',
      )
    );
  }

  get allowBOI() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) => permission.role.internalName === 'Expert BOI',
      )
    );
  }

  get allowCoordinator() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) => permission.role.internalName === 'Koordynator',
      )
    );
  }

  get allowDirector() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) => permission.role.internalName === 'Dyrektor',
      )
    );
  }

  get allowAddEntities() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Entity' && permission.create,
      )
    );
  }

  get allowEditEntities() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Entity' && permission.update,
      )
    );
  }

  get allowDeleteEntities() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Entity' && permission.delete,
      )
    );
  }

  get allowAddDocuments() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.create,
      )
    );
  }

  get allowEditDocuments() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.update,
      )
    );
  }

  get allowDeleteDocuments() {
    return this.isSuperAdmin;
  }

  get allowAddOffers() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.create,
      )
    );
  }

  get allowEditOffers() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'Documents' && permission.update,
      )
    );
  }

  get allowEditNew() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'UnassignedDocuments' &&
          permission.update,
      )
    );
  }

  get allowDeleteNew() {
    return (
      this.isSuperAdmin ||
      this.userPermissions.some(
        (permission) =>
          permission.action.internalName === 'UnassignedDocuments' &&
          permission.delete,
      )
    );
  }

  get isUserAuthorized() {
    return this.activeAuthState === 'authenticated';
  }

  get lastAuthState() {
    return this.activeAuthState;
  }

  get userDetails() {
    return this.user;
  }

  get isSystemAdmin() {
    return (
      this.user?.accountType?.internalName ===
      AUTH_CONSTANTS.ACCOUNT_TYPE.SYSTEM_ADMIN
    );
  }

  get jwtToken() {
    return this.token;
  }

  get userData$() {
    return this._userData.asObservable();
  }

  get authState$() {
    return this._authState.asObservable();
  }

  constructor(
    private storageService: StorageService,
    private router: Router,
    private httpClient: HttpClient,
  ) {
    this.authState$.subscribe((state) => {
      this.activeAuthState = state;
    });

    this.readUserFromStorage();
  }

  private readUserFromStorage() {
    const token = this.storageService.read<string>(AUTH_KEY);

    if (!token) {
      return this.clearAuthData(true);
    }

    this.setToken(token);
  }

  private saveUserToStorage() {
    this.storageService.save(AUTH_KEY, this.token);
  }

  private setToken(token: string, propagate: boolean = true) {
    if (token === null || token === '') return this.clearAuthData(propagate);

    const decodedToken = jwt_decode<ApiJwtPayload>(token);

    if (!decodedToken) return this.clearAuthData(propagate);

    const expirationDate = new Date(decodedToken.exp! * 1000);

    if (expirationDate < new Date()) return this.clearAuthData(propagate);

    this.token = token;
    this.tokenExpirationDate = expirationDate;

    this.saveUserToStorage();

    if (propagate) {
      this._authState.next('authenticated');
    }

    if (this.activeAuthState === 'loading' || !this.activeAuthState) {
      this.activeAuthState = 'authenticated';
    }

    this.loadUserData(propagate);
  }

  private clearAuthData(propagate: boolean) {
    if (propagate) {
      this._authState.next('unauthenticated');
      this._userData.next(null);
    }

    this.storageService.remove(AUTH_KEY);

    this.token = null;
    this.tokenExpirationDate = null;
    this.user = null;
  }

  private loadUserData(propagate: boolean) {
    const tokenRequest = this.httpClient.get<
      ApiResponseModel<UserDataResponse>
    >(environment.apiUrl + AUTH_URLS.userInfo, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

    return lastValueFrom(tokenRequest).then((response) => {
      this.setUser(response.result, propagate);
    });
  }

  private setUser(userData: UserDataResponse, propagate: boolean = true) {
    this.user = userData;

    if (propagate) {
      this._userData.next(userData);
    }
  }

  public login(data: LoginRequest): Promise<ApiResponseModel<LoginResponse>> {
    const tokenRequest = this.httpClient.post<ApiResponseModel<LoginResponse>>(
      environment.apiUrl + AUTH_URLS.login,
      data,
    );

    return lastValueFrom(tokenRequest).then((response) => {
      this.onLoginSuccess(response);
      return response;
    });
  }

  private onLoginSuccess(response: ApiResponseModel<LoginResponse>) {
    this.setToken(response.result.token, true);
  }

  public logout() {
    this.router.navigateByUrl('auth');
    this.clearAuthData(true);
  }

  public remindPassword(data: RemindPasswordRequest) {
    const remindPasswordRequest = this.httpClient.post<
      ApiResponseModel<RemindPasswordResponse>
    >(environment.apiUrl + AUTH_URLS.remindPassword, data);

    return remindPasswordRequest;
  }

  public resetPassword(data: ResetPasswordRequest) {
    const ResetPasswordRequest = this.httpClient.put<
      ApiResponseModel<ResetPasswordResponse>
    >(environment.apiUrl + AUTH_URLS.resetPassword, data);

    return ResetPasswordRequest;
  }
}

export type AuthState =
  | 'loading'
  | 'reloading'
  | 'authenticated'
  | 'unauthenticated';

export interface ApiJwtPayload extends JwtPayload {
  email: string;
  userType: string;
  type: string;
  uuid: string;
}

export const AUTH_URLS = {
  login: '/api/v1/auth/token',
  remindPassword: '/api/v1/auth/password',
  resetPassword: '/api/v1/auth/password',
  userInfo: '/api/v1/auth/me',
};
