import { Injectable } from '@angular/core';
import {STORAGE_PUBLIC_KEY} from "../@shared/utils/consts";
import * as CryptoJS from 'crypto-js';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

  private keyBase = `${STORAGE_PUBLIC_KEY}_${environment.envName}_`;
  private hashKey = environment.storageHashKey;

  save(key: string, object: any, storage: StorageType = StorageType.Local): void {
    const json = this.stringifyObject(object);
    this.saveToStorage(key, json, storage);
  }

  remove(key: string, storage: StorageType = StorageType.Local) {
    storage === StorageType.Local ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
  }

  read<T>(key: string, storage: StorageType = StorageType.Local): T | null {
    const json = this.readFromStorage(key, storage);
    return json ? JSON.parse(json) : null;
  }

  private readFromStorage(key: string, storage: StorageType): string | null {
    const json = storage === StorageType.Local ? localStorage.getItem(key) : sessionStorage.getItem(key);
    return json ? this.decryptJson(json) : null;
  }

  private saveToStorage(key: string, json: string, storage: StorageType) {
    const hashedJson = this.encryptJson(json);
    storage === StorageType.Local ? localStorage.setItem(key, hashedJson) : sessionStorage.setItem(key, hashedJson);
  }
  private stringifyObject(object: any) {
    return JSON.stringify(object);
  }

  private encryptJson(json: string): string {
    return CryptoJS.AES.encrypt(json, this.hashKey).toString();
  }

  private decryptJson(json: string): string {
    try {
      return CryptoJS.AES.decrypt(json, this.hashKey).toString(CryptoJS.enc.Utf8);
    } catch (err) {
      return '';
    }
  }
}

export enum StorageType {
  Local,
  Session
}
