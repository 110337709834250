import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule, CurrencyPipe, DatePipe, registerLocaleData} from "@angular/common";
import {CustomMaterialModule} from "./@theme/custom-material.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import {HttpClientModule} from "@angular/common/http";
import localeFr from '@angular/common/locales/fr';
import {MAT_SELECT_CONFIG} from '@angular/material/select';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    AngularSvgIconModule.forRoot()
  ],
  providers: [CurrencyPipe, DatePipe, {
    provide: MAT_SELECT_CONFIG,
    useValue: {overlayPanelClass: 'mat-select-panel-overlay'}
  }],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
