import {Component} from '@angular/core';
import {AuthService} from './_services/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {ApiBaseService} from './_services/api-base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'PlatIN';

  private lastUrl = '';
  private dragoverHandler = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  private dropHandler = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  constructor(private authService: AuthService, private router: Router, private apiBaseService: ApiBaseService) {
    this.checkNavigation(document.location.href);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkNavigation(event.url);
      }
    });
  }

  ngOnInit() {
    document.addEventListener('dragover', this.dragoverHandler);
    document.addEventListener('drop', this.dropHandler);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.dragoverHandler);
    document.removeEventListener('drop', this.dropHandler);
  }

  checkNavigation(url: string) {
    if (this.authService.isUserAuthorized && url.includes('auth')) {
      this.router.navigateByUrl('/app/staff');
    } else if (url.includes('investor-form')) {
      this.router.navigateByUrl('/investor-form');
    } else if (!this.authService.isUserAuthorized && !url.includes('auth')) {
      this.router.navigateByUrl('/auth');
    }
  }
}
